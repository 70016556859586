import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"
import "./layout.css"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery } from "gatsby"

const StyledLayout = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "stars.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#1e2227`}
        >{children}</BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #1e2227;
  color: #fff;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

const Layout = ({ children }) => {
  return (
    <StyledBackgroundSection>
      <StyledLayout>{children}</StyledLayout>
    </StyledBackgroundSection>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
