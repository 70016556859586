import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Audio } from "../components/audio"

const IndexPage = () => (
  <Layout>
    <SEO title="Love You to Pluto and Back" />
    <Audio />
  </Layout>
)

export default IndexPage
