import React, { useState, useRef } from "react";
import ReactAudioPlayer from "react-audio-player";
import * as song from "../audio/pluto.mp3";
import { Lyrics } from "./lyrics";

const lyrics = {
  18.1: `the first time`,
  22.0: `we were lucky`,
  25.7: `you were mine`,
  29.9: `and you ran from me`,
  33.3: `in my darkness`,
  35.9: (<>in my darkness <br />your smile is my light</>),
  41: `side by side again`,
  43.4: `I know it's right`,
  47.7: `how much do I love you`,
  53: (<>how much do I love you, <br />you ask?</>),
  55: `all the way to the moon`,
  60.5: (<>all the way to the moon <br />and back</>),
  62.5: `how long will I dance with you love?`,
  68: `if I get my way`,
  70.5: `all the way to pluto`,
  75.5: (<>all the way to pluto <br />and back</>),
  139.9: `now we fly`,
  144.0: `to imerovigli`,
  147.7: `took my time`,
  151.9: `but you're all I need`,
  155: `in my darkness`,
  157.5: (<>in my darkness <br />your smile is my light</>),
  162.5: `side by side again`,
  169.5: `how much do I love you`,
  174.7: (<>how much do I love you, <br />you ask?</>),
  177: `all the way to the moon`,
  182.3: (<>all the way to the moon <br />and back</>),
  183.9: `how long will I dance with you love?`,
  190: `if I get my way`,
  192.5: `all the way to pluto`,
  197.5: (<>all the way to pluto <br />and back</>),
  200: `how much do I love you`,
  205.2: (<>how much do I love you, <br />you ask?</>),
  207.5: `all the way to the moon`,
  212.8: (<>all the way to the moon <br />and back</>),
  214.4: `how long will I dance with you love?`,
  220.5: `if I get my way`,
  223: `all the way to pluto`,
  228: (<>all the way to pluto <br />and back</>),
  240: `love you, Amee!`
}

export const Audio = () => {
  const [currentLyric, setCurrentLyric] = useState(null)

  const onListen = time => {
    const timeRounded = Math.round(time * 10) / 10

    if (lyrics[timeRounded]) {
      setCurrentLyric(lyrics[timeRounded])
    }

  }

  return (
    <>
      <ReactAudioPlayer
        src={song}
        autoPlay
        controls
        listenInterval={25}
        onListen={onListen}
      />
      <Lyrics>{currentLyric}</Lyrics>
    </>
  )
}
