import React from "react"
import styled from "@emotion/styled"

const StyledLyrics = styled.div`
  font-size: 36px;
  line-height: 1.4;
  padding: 0 40px;
  text-align: center;
  min-height: 150px;
  min-width: 100%;
  font-family: 'Poppins', sans-serif;
`

export const Lyrics = ({ children }) => <StyledLyrics>{children}</StyledLyrics>
